// src/dexie.js
import Dexie from 'dexie';

const db = new Dexie('treeDatabase');

// Define the database schema
db.version(4).stores({
  trees: '++id, data',
  records: '++id, token, data,blob',
});

// Example of adding data
export const addRecord = async (record) => {
  try {
    const id = await db.records.add(record);
    return id;
  } catch (error) {
    console.error("Failed to add record:", error);
  }
};

// Example of retrieving all records
export const getAllRecords = async () => {
  try {
    return await db.records.toArray();
  } catch (error) {
    console.error("Failed to retrieve records:", error);
  }
};

// Example of retrieving a single record by ID
export const getRecordById = async (id) => {
  try {
    return await db.records.get(id);
  } catch (error) {
    console.error("Failed to retrieve record:", error);
  }
};

// Example of updating a record by ID
export const updateRecord = async (id, updatedData) => {
  try {
    return await db.records.update(id, updatedData);
  } catch (error) {
    console.error("Failed to update record:", error);
  }
};

// Example of deleting a record by ID
export const deleteRecord = async (id) => {
  try {
    return await db.records.delete(id);
  } catch (error) {
    console.error("Failed to delete record:", error);
  }
};


export const clearTrees = async () => {
  try {
    return await db.trees.clear();
  } catch (error) {
    console.error("Failed to retrieve records:", error);
  }
};
export const addTreeRecord = async (record) => {
  try {
    const id = await db.trees.add(record);
    return id;
  } catch (error) {
    console.error("Failed to add record:", error);
  }
};

export const getAllTrees = async () => {
  try {
    return await db.trees.toArray();
  } catch (error) {
    console.error("Failed to retrieve records:", error);
  }
};
export const addSurveyRecord = async (record) => {
  try {
    const id = await db.records.add(record);
    return id;
  } catch (error) {
    console.error("Failed to add record:", error);
  }
};
export const getAllSurveys = async () => {
  try {
    return await db.records.toArray();
  } catch (error) {
    console.error("Failed to retrieve records:", error);
  }
};

// Example of deleting a record by ID
export const deleteSurveyRecord = async (id) => {
  try {
      
    return await db.records.where("id").equals(parseInt(id)).delete().then(function(deleteCount) {
            console.log ("Deleted " + deleteCount + " rows");
          });
          
  } catch (error) {
    console.error("Failed to delete record:", error);
  }
};
 
export default db;
