<template> 
 <div class="page-header"  >
    <div class="loader-wrapper">
      <div class="loader"> 
        <div class="loader4"></div>
      </div>
    </div>
    
        <div class="header-wrapper row m-0">
          <form class="form-inline search-full col" action="#" method="get">
            <div class="form-group w-100">
              <div class="Typeahead Typeahead--twitterUsers">
                <div class="u-posRelative"> 
                  <input class="demo-input Typeahead-input form-control-plaintext w-100" type="text" placeholder="Search Riho .." name="q" title="" autofocus>
                  <div class="spinner-border Typeahead-spinner" role="status"><span class="sr-only">Loading... </span></div><i class="close-search" data-feather="x"></i>
                </div>
                <div class="Typeahead-menu"> </div>
              </div>
            </div>
          </form>
          <div class="header-logo-wrapper col-auto p-0">  
            <div class="logo-wrapper"> <a href="#">
              <img class="img-fluid for-light" src="../../assets/images/logo/logo_dark.png" alt="logo-light">
              <img class="img-fluid for-dark" src="../../assets/images/logo/logo.png" alt="logo-dark"></a></div>
            <div class="toggle-sidebar"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-align-center status_toggle middle sidebar-toggle"><line x1="18" y1="10" x2="6" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="18" y1="18" x2="6" y2="18"></line></svg></div>
             
          </div>
          <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
            <div> <a class="toggle-sidebar" href="#"> <i class="iconly-Category icli"> </i></a>
              <div class="d-flex align-items-center gap-2 ">
                <h4 class="f-w-600">Welcome {{employees.empname}}</h4><img class="mt-0" src="../../assets/images/hand.gif" alt="hand-gif">
              </div>
            </div>
            <div class="welcome-content d-xl-block d-none"><span class="text-truncate col-12">Have a nice day. </span></div>
          </div>
          <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
            <ul class="nav-menus"> 
              <!--<li class="d-md-block d-none"> -->
              <!--  <div class="form search-form mb-0">-->
              <!--    <div class="input-group"><span class="input-icon">-->
              <!--        <svg>-->
              <!--          <use href="../../assets/svg/icon-sprite.svg#search-header"></use>-->
              <!--        </svg>-->
              <!--        <input class="w-100" type="search" placeholder="Search"></span></div>-->
              <!--  </div>-->
              <!--</li>-->
              <li class="d-md-none d-block"> 
                <div class="form search-form mb-0">
                  <div class="input-group"> <span class="input-show"> 
                      <svg id="searchIcon">
                        <use href="../../assets/svg/icon-sprite.svg#search-header"></use>
                      </svg>
                      <div id="searchInput">
                        <input type="search" placeholder="Search">
                      </div></span></div>
                </div>
              </li>
             
              <li> 
                <div class="mode"><i class="moon" data-feather="moon"> </i></div>
              </li>
            
              <li class="profile-nav onhover-dropdown"> 
                <div class="media profile-media">
                    <i class="fa fa-user"></i>
                    <!--<img class="b-r-10" src="../../assets/images/dashboard/profile.png" alt="">-->
                  <div class="media-body d-xxl-block d-none box-col-none">
                    <div class="d-flex align-items-center gap-2"> <span>{{employees.empname}} </span><i class="middle fa fa-angle-down"> </i></div>
                    <p class="mb-0 font-roboto">Admin</p>
                  </div>
                </div>
                <ul class="profile-dropdown onhover-show-div"> 
                <li><a class=" " >{{employees.empname}}</a></li>
                  <li><a class="btn btn-pill btn-outline-primary btn-sm" @click="logout">Log Out</a></li>
                </ul>
              </li>
            </ul>
          </div>
          </div>
      </div>
      
        <!-- Page Sidebar Start-->
       
        <div class="sidebar-wrapper" data-layout="stroke-svg" >
          <div class="logo-wrapper"><a href="#">
              <img   v-if="employees.emprole!=999" class="img-fluid" :src="getLogo()" alt="">
              <img   v-else class="img-fluid" src="../../assets/images/logo/logo_new_white.png" alt="">
              
              </a>
              
            <div class="back-btn"><i class="fa fa-angle-left"> </i></div>
            <div class="toggle-sidebar"  @click="sidebar_btn" checked="checked"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid status_toggle middle sidebar-toggle"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg></div>
          </div>
          <div class="logo-icon-wrapper"><a href="#"><img class="img-fluid" src="../../assets/images/logo/logo-icon.png" alt=""></a></div>
          <nav class="sidebar-main">
            <div class="left-arrow" id="left-arrow"><i data-feather="arrow-left"></i></div>
            <div id="sidebar-menu">
              <ul class="sidebar-links" id="simple-bar" data-simplebar="init" style="display: block;"><div class="simplebar-wrapper" style="margin: 0px;"><div class="simplebar-height-auto-observer-wrapper"><div class="simplebar-height-auto-observer"></div></div><div class="simplebar-mask"><div class="simplebar-offset" style="right: 0px; bottom: 0px;"><div class="simplebar-content-wrapper" style="height: 100%; overflow: hidden scroll;"><div class="simplebar-content" style="padding: 0px;">
                <li class="back-btn"><a href="#"><img class="img-fluid" src="../../assets/images/logo/logo-icon.png" alt=""></a>
                  <div class="mobile-back text-end"> <span>Back </span><i class="fa fa-angle-right ps-2" aria-hidden="true"></i></div>
                </li>
                <li class="pin-title sidebar-main-title">
                  <div> 
                    <h6>Pinned</h6>
                  </div>
                </li>
                <li class="sidebar-main-title">
                  <div>
                    <h6 class="lan-1">General</h6>
                  </div>
                </li>
               <li :class="`${isMobile ? 'sidebar-list back-btn' : 'sidebar-list'}`"  v-if="employees && employees.emprole!=4"><i class="fa fa-thumb-tack"></i>
                  <router-link class="sidebar-link sidebar-title link-nav" to="/dashboard">
                    <svg class="stroke-icon">
                      <use href="../../assets/svg/icon-sprite.svg#stroke-home"></use>
                    </svg>
                    <svg class="fill-icon">
                      <use href="../../assets/svg/icon-sprite.svg#fill-file"></use>
                    </svg><span>Dashboard</span><div class="according-menu"><i class="fa fa-angle-right"></i></div></router-link></li>
                
                
                <li :class="`${isMobile ? 'sidebar-list back-btn' : 'sidebar-list'}`"   v-else ><i class="fa fa-thumb-tack"></i>
                  <router-link class="sidebar-link sidebar-title link-nav" to="/frontdash">
                    <svg class="stroke-icon">
                      <use href="../../assets/svg/icon-sprite.svg#stroke-home"></use>
                    </svg>
                    <svg class="fill-icon">
                      <use href="../../assets/svg/icon-sprite.svg#fill-file"></use>
                    </svg><span>Dashboard</span><div class="according-menu"><i class="fa fa-angle-right"></i></div></router-link></li>
                    
                  
                    
                
                <li :class="`${isMobile ? 'sidebar-list back-btn' : 'sidebar-list'}`"   v-if="employees.emprole!=4"><i class="fa fa-thumb-tack"> </i><a class="sidebar-link sidebar-title" href="#">
                    <svg class="stroke-icon">
                      <use href="../../assets/svg/icon-sprite.svg#star"></use>
                    </svg>
                    <svg class="fill-icon">
                      <use href="../../assets/svg/icon-sprite.svg#fill-home"></use>
                    </svg><span class="lan-3">Master</span><div class="according-menu"><i class="fa fa-angle-right"></i></div></a>
                  <ul class="sidebar-submenu" style="display: none;">
                  <li><router-link to="/nagarpalika" v-if="employees && employees.isMainAdmin==1">Nagarpalika</router-link></li>  
                    <li><router-link to="/users">Users</router-link></li> 
                    
                  </ul>
                </li> 
                
                <li class="sidebar-list"  v-if="employees.emprole!=4"><i class="fa fa-thumb-tack"> </i><a class="sidebar-link sidebar-title" href="#">
                    <svg class="stroke-icon">
                      <use href="../../assets/svg/icon-sprite.svg#stroke-project"></use>
                    </svg>
                    <svg class="fill-icon">
                      <use href="../../assets/svg/icon-sprite.svg#fill-home"></use>
                    </svg><span class="lan-3">Survey</span><div class="according-menu"><i class="fa fa-angle-right"></i></div></a>
                  <ul class="sidebar-submenu" style="display: none;">
                  <li><router-link to="/survey"  :class="`${isMobile ? 'back-btn' : ''}`"  v-if="employees && employees.emprole==999">Category</router-link></li>  
                    <li><router-link to="/question" :class="`${isMobile ? 'back-btn' : ''}`"  v-if="employees && employees.emprole==999">Question</router-link></li> 
                    <li><router-link to="/surveycats" :class="`${isMobile ? 'back-btn' : ''}`" >Report</router-link></li> 
                    
                  </ul>
                </li> 
                  
                  <li :class="`${isMobile ? 'sidebar-list back-btn' : 'sidebar-list'}`"  v-if="employees.emprole==4" ><i class="fa fa-thumb-tack"></i>
                  <router-link class="sidebar-link sidebar-title link-nav" to="/newsurvey">
                    <svg class="stroke-icon">
                      <use href="../../assets/svg/icon-sprite.svg#fill-project"></use>
                    </svg>
                    <svg class="fill-icon">
                      <use href="../../assets/svg/icon-sprite.svg#fill-file"></use>
                    </svg><span>Start Survey</span><div class="according-menu"><i class="fa fa-angle-right"></i></div></router-link></li>
                    
                   <li :class="`${isMobile ? 'sidebar-list back-btn' : 'sidebar-list'}`"  ><i class="fa fa-thumb-tack"></i>
                  <router-link class="sidebar-link sidebar-title link-nav" to="/treereport">
                    <svg class="stroke-icon">
                      <use href="../../assets/svg/icon-sprite.svg#stroke-task"></use>
                    </svg>
                    <svg class="fill-icon">
                      <use href="../../assets/svg/icon-sprite.svg#fill-file"></use>
                    </svg><span>Report</span><div class="according-menu"><i class="fa fa-angle-right"></i></div></router-link></li>
                    
                    
                   <li :class="`${isMobile ? 'sidebar-list back-btn' : 'sidebar-list'}`"  ><i class="fa fa-thumb-tack"></i>
                  <router-link class="sidebar-link sidebar-title link-nav" to="/tankreport">
                    <svg class="stroke-icon">
                      <use href="../../assets/svg/icon-sprite.svg#stroke-task"></use>
                    </svg>
                    <svg class="fill-icon">
                      <use href="../../assets/svg/icon-sprite.svg#fill-file"></use>
                    </svg><span>Tank Report</span><div class="according-menu"><i class="fa fa-angle-right"></i></div></router-link></li>
                    
                    
                    
                          <li :class="`${isMobile ? 'sidebar-list back-btn' : 'sidebar-list'}`"  ><i class="fa fa-thumb-tack"></i>
                  <router-link class="sidebar-link sidebar-title link-nav" to="/imgreport">
                    <svg class="stroke-icon">
                      <use href="../../assets/svg/icon-sprite.svg#stroke-gallery"></use>
                    </svg>
                    <svg class="fill-icon">
                      <use href="../../assets/svg/icon-sprite.svg#stroke-gallery"></use>
                    </svg><span>Image Report</span><div class="according-menu"><i class="fa fa-angle-right"></i></div></router-link></li>
                  
                 
                
                  <li class="sidebar-list"  @click="logout"><i class="fa fa-thumb-tack"></i>
                  <router-link class="sidebar-link sidebar-title link-nav" to="/dashboard">
                    <svg class="stroke-icon">
                      <use href="../../assets/svg/icon-sprite.svg#back-arrow"></use>
                    </svg>
                    <svg class="fill-icon">
                      <use href="../../assets/svg/icon-sprite.svg#fill-file"></use>
                    </svg><span>Log Out</span><div class="according-menu"><i class="fa fa-angle-right"></i></div></router-link></li>
                    
                <h6 class="text-white text-center mt-5">Powered by PRAPANCH</h6>
              </div></div></div></div><div class="simplebar-placeholder" style="width: auto; height: 2055px;"></div></div>
              </ul>
              <div class="right-arrow" id="right-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></div>
            </div>
          </nav>
        </div> 
      
         
        
</template>
 
<style lang="scss" >
  @import "../../assets/scss/utils/_variables.scss"; //Here i add extra "./"(current directory) 
  @import "../../assets/scss/components/_scrollbar.scss"; //Here i add extra "./"(current directory)
</style>
<style scoped>
.simplebar-wrapper{
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
.simplebar-mask{
  top:0;
}

.sidebar-wrapper{
    border-radius: 0 !important;
}
</style>
<script>
  import axios from 'axios'; 
export default {
  name: 'Header',
  created() {
      if(localStorage['token']){
        var self=this;
        axios.post('is_authenticated',{token:localStorage['token']}).then(function (response) {
                self.employees = response.data.user; 
                self.nagarpalika = response.data.nagarpalika;
                window.scrollTo(0, 0);
            });

      }else
      this.$router.push('/');
 
  },
	mounted() {
        this.isMobileFunc();
        $(".loader-wrapper").hide();
        $("#pageWrapper").on('click',function(){
            if(this.sidebar_status)
                sidebar_btn();
        });
	}, data() {
        return {
          employees: {empname:'',emprole:'0',isMainAdmin:0},
          nagarpalika: {logo:''},
            sidebar_status:true,
            isMobile: false
        }
    },
    
    methods:{
        touchEndMethod (touchEvent) {
              console.log("on left");
             this.idebar_btn();
           },
      sidebar_btn(){ 
         this.sidebar_status=!this.sidebar_status;
        if(this.sidebar_status)
          $("#pageWrapper").toggleClass("sidebar_closed");
        else
          $("#pageWrapper").addClass("sidebar_closed"); 
      },
      
    logout() {
      localStorage.removeItem('token');
      window.location.replace(window.location.origin);
    },
    getLogo(){
        if(this.nagarpalika && this.nagarpalika.logo)
        return this.$hostname+"writable/uploads/logos/"+this.nagarpalika.logo;
    },
     isMobileFunc() {
            if( screen.width <= 460 ) {
                    this.isMobile= true;
                }
                else {
                    this.isMobile= false;
                }
         },
    
  }
  
}
</script>
 
