import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import UsersView from '../views/master/Users.vue' 
import Categories from '../views/master/Categories.vue'
import Nagarpalika from '../views/master/Nagarpalika.vue'
import Question from '../views/master/Question.vue'
import Users from '../views/master/Users.vue' 
import AddProduct from '../views/master/AddProduct.vue' 
import Survey from '../views/master/Survey.vue' 
import LoginView from '../views/Login.vue'   
import TreeReport from '../views/report/TreeReport.vue'
import OfflineReport from '../views/report/OfflineReport.vue'
import FrontDash from '../views/frontuser/FrontDash.vue'
import takesurvey from '../views/frontuser/StartSurvey.vue'
import surveyreport from '../views/report/SurveyReport.vue'
import NewSurvey from '../views/frontuser/NewSurvey.vue'
import Capture from '../views/frontuser/Capture.vue'
import SurveyCats from '../views/frontuser/SurveyCats.vue' 
import EditSurvey from '../views/frontuser/EditSurvey.vue' 
import ImgReport from '../views/report/ImgReport.vue'
import TankSurvey from '../views/frontuser/TankSurvey.vue'
import TankReport from '../views/report/TankReport.vue'


const routes = [
   {
    path: '/tankreport',
    name: 'tankreport',
    component: TankReport
  },
   {
    path: '/tanksurvey',
    name: 'tanksurvey',
    component: TankSurvey
  },
  {
    path: '/surveyreport',
    name: 'surveyreport',
    component: surveyreport
  },
   {
    path: '/takesurvey',
    name: 'takesurvey',
    component: takesurvey
  },
   {
    path: '/editsurvey',
    name: 'editsurvey',
    component: EditSurvey
  },
   {
     path: '/surveycats',
     name: 'surveycats',
     component: SurveyCats
   },
  {
    path: '/frontdash',
    name: 'frontdash',
    component: FrontDash
  },
  {
    path: '/newsurvey',
    name: 'newsurvey',
    component: NewSurvey
  },
  {
    path: '/capture',
    name: 'capture',
    component: Capture
  },
  
  {
    path: '/treereport',
    name: 'treereport',
    component: TreeReport
  },
   {
    path: '/imgreport',
    name: 'imgreport',
    component: ImgReport
  },
  {
    path: '/offlinereport',
    name: 'offlinereport',
    component: OfflineReport
  },
  
  
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
  path: '/survey',
  name: 'survey',
  component: Survey
  },
  {
    path: '/question',
    name: 'question',
    component: Question
    },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },   
  {
    path: '/categories',
    name: 'categories',
    component: Categories
  },
  {
    path: '/nagarpalika',
    name: 'nagarpalika',
    component: Nagarpalika
  },
  {
    path: '/users',
    name: 'users',
    component: Users
  },
  {
    path: '/dashboard',
    name: 'home',
    component: HomeView
  },  
  // {
  //   path: '/users',
  //   name: 'users',
  //   component: UsersView
  // }, 
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
