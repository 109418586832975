<template>
    <div class="modal fade" id="departmentAdd" tabindex="-1" role="dialog" aria-labelledby="departmentAdd"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Department</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                        <div class="col-sm-12">
                            <div class="row g-3">
                                <div class="col-md-12 position-relative">
                                    <label class="form-label">Department Name</label>
                                    <input class="form-control" type="text" v-model="dept.name"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Department Status</label>
                                    <select class="form-select" required="" v-model="dept.status">
                                        <option selected="" disabled="" value="">Choose...</option>
                                        <option value="1">Active</option>
                                        <option value="2">De-Active</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <button class="btn btn-primary" @click="save_dept">Add Department</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="categoryAdd" tabindex="-1" role="dialog" aria-labelledby="categoryAdd"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Category</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                        <div class="col-sm-12">
                            <div class="row g-3">
                                <div class="col-md-12 position-relative">
                                    <label class="form-label">Category Name</label>
                                    <input class="form-control" type="text" v-model="category.name"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Category Status</label>
                                    <select class="form-select" required="" v-model="category.status">
                                        <option selected="" disabled="" value="">Choose...</option>
                                        <option value="1">Active</option>
                                        <option value="2">De-Active</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <button class="btn btn-primary" @click="save_category">Add Category</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="userAdd" tabindex="-1" role="dialog" aria-labelledby="userAdd"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add User</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper">
                        <div class="col-sm-12">
                            <div class="row g-3">
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">User Code</label>
                                    <input class="form-control" type="text" v-model="user.usercode"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">User Name</label>
                                    <input class="form-control" type="text" v-model="user.username"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">User Contact</label>
                                    <input class="form-control" type="text" v-model="user.usercontact"
                                        placeholder="Type Here..." required="">
                                </div>
                               
                                <div class="col-md-6 position-relative"  >
                                    <label class="form-label">User Nagarpalika</label>
                                    <select class="form-select" required="" v-model="user.userdept">
                                        <option selected=""   v-if="userDetails.isMainAdmin==1" disabled="" value="0">Choose...</option>
                                        <template v-for="(item ,index)  in nagarpalika_list" :key="item.id">
                                             <option  v-if="userDetails.isMainAdmin==1" :value="item.id">{{item.name}}</option>   
                                             <option  v-else-if="userDetails.isMainAdmin==0 && userDetails.empdept==item.id" selected  :value="item.id">{{item.name}}</option>     
                                        </template>
                                        
                                        
                                    </select>
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">User Password</label>
                                    <input class="form-control" type="text" v-model="user.userpwd"
                                        placeholder="Type Here..." required="">
                                </div> 
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">User Role</label>
                                    <select class="form-select" required="" v-model="user.userrole">
                                        <option selected="" disabled="" value="">Choose...</option>
                                        <option v-if="userDetails.isMainAdmin==1"   value="999">System Admin</option>
                                        <option value="2">Nagarpalika Admin</option>
                                        <option value="3">Nagarpalika HOD</option>
                                        <option value="4">Nagarpalika Employee</option>
                                    </select>
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">User Status</label>
                                    <select class="form-select" required="" v-model="user.userstatus">
                                        <option selected="" disabled="" value="">Choose...</option>
                                        <option value="1">Active</option>
                                        <option value="2">De-Active</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <button class="btn btn-primary" @click="save_user">Save Data</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
   
   <div class="modal modal-lg fade" id="nagarpalikaAdd" tabindex="-1" role="dialog" aria-labelledby="nagarpalikaAdd"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Nagarpalika</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                        <div class="col-sm-12">
                            <div class="row g-3">
                                <div class=" col-md-6 col-12 position-relative">
                                    <label class="form-label">Nagarpalika Name</label>
                                    <input class="form-control" type="text" v-model="nagarpalika.name"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class=" col-md-6 col-12 position-relative">
                                    <label class="form-label">Usercode Prefix </label>
                                    <input class="form-control" type="text" v-model="nagarpalika.usercode"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class=" col-md-6 col-12 position-relative">
                                    <label class="form-label">Nagarpalika Contact </label>
                                    <input class="form-control" type="number" v-model="nagarpalika.contact"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class=" col-md-12 col-12 position-relative">
                                    <label class="form-label">Nagarpalika Address</label>
                                    <input class="form-control" type="text" v-model="nagarpalika.address"
                                        placeholder="Type Here..." required="">
                                </div>
                               
                                <div class=" col-md-6 col-12 position-relative">
                                    <label class="form-label">Nagarpalika Logo</label>
                                    <input class="form-control" type="file"  @change="upload($event)"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class=" col-md-6 col-12 position-relative">
                                    <label class="form-label">Nagarpalika Lattitude</label>
                                    <input class="form-control" type="text"    v-model="nagarpalika.default_lng"
                                        placeholder="Lattitude,Longitude" required="">
                                </div>
                                <div class=" col-md-6 col-12 position-relative">
                                    <label class="form-label">Nagarpalika Long</label>
                                    <input class="form-control" type="text"    v-model="nagarpalika.default_lat"
                                        placeholder="Lattitude,Longitude" required="">
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label class="form-label">Nagarpalika Status</label>
                                    <select class="form-select" required="" v-model="nagarpalika.status">
                                        <option selected="" disabled="" value="">Choose...</option>
                                        <option value="1">Active</option>
                                        <option value="2">De-Active</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <button class="btn btn-primary" @click="save_nagarpalika">Add Nagarpalika</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

   <div class="modal modal-lg fade" id="questionAdd" tabindex="-1" role="dialog" aria-labelledby="questionAdd"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Question</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                        <div class="col-sm-12">
                            <div class="row g-3">
                                <div class=" col-md-6 col-12 position-relative">
                                    <label class="form-label">Select Survey</label>
                                     <select class="form-select" required="" v-model="question.survey_id">
                                        <option selected="" disabled="" value="0">Choose...</option>
                                         <option  v-for="(item ,index)  in survey_list" :key="item.id" :value="item.id">{{item.name}}</option>
                                        
                                    </select>
                                     
                                </div>
                                  <div class=" col-md-12 col-12 position-relative">
                                    <label class="form-label">Question Here</label>
                                    <input class="form-control" type="text" v-model="question.que"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class=" col-md-6 col-12 position-relative">
                                    <label class="form-label">Answer Type</label>
                                     <select class="form-select" required="" v-model="question.questiontype">
                                        <option selected=""   value="1">Text</option>
                                        <option   value="2">Yes/No</option>
                                        <option  value="3">Image Upload</option>
                                        <option    value="4">Range</option>
                                        <option    value="5">Location</option>
                                    </select>
                                   
                                </div>
                                <div class=" col-md-6 col-12 position-relative">
                                    <label class="form-label">Marks</label>
                                    <input class="form-control" type="number" v-model="question.marks"
                                        placeholder="Type Here..." required="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <button class="btn btn-primary" @click="save_question">Add Question</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="surveyAdd" tabindex="-1" role="dialog" aria-labelledby="surveyAdd"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Survey</h4>
                    <button class="btn-close py-0" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="modal-toggle-wrapper ">
                        <div class="col-sm-12">
                            <div class="row g-3">
                                 <div class="col-md-12 position-relative" >
                                    <label class="form-label">User Nagarpalika</label>
                                    <select class="form-select" required="" v-model="survey.nagarpalika_id">
                                        <option selected="" disabled="" value="0">Choose...</option>
                                         <option  v-for="(item ,index)  in nagarpalika_list" :key="item.id" :value="item.id">{{item.name}}</option>
                                        
                                    </select>
                                </div>
                                <div class=" col-md-12 col-12 position-relative">
                                    <label class="form-label">Survey Name</label>
                                    <input class="form-control" type="text" v-model="survey.name"
                                        placeholder="Type Here..." required="">
                                </div>
                                <div class="col-md-12 position-relative">
                                    <label class="form-label">Survey Status</label>
                                    <select class="form-select" required="" v-model="survey.status"> 
                                        <option selected="" value="1">Active</option>
                                        <option value="2">De-Active</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12">
                        <button class="btn btn-primary" @click="save_survey">Add Survey</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    .hide{
        display: none;
    }
</style>
<script>

    import axios from 'axios';
    export default {
        props: {
            selectedProduct:Array,
            isOpen:0,
            isEditMode:0,
            initialData:Array,
            nagarpalika_list:Array,
            survey_list:Array,
            userDetails:Array ,
            user_code:0
        },
        name: 'ModalsPart',
        emits: ["getDeptData", "getEmpData", "getProductData", "getRoomsData", "getGatePassData","add-product"],
        mounted() {
            this.getBasics();
            if(this.initialData){
                this.product=this.initialData;
            }
        },
        data() {
            return {
                emp: { "empname": '', "empcode": '', "empdept": '', "emppwd": '', "emprole": '', "empstatus": '' },
                user: { "username": '',"usercontact":'',"useremail":'', "usercode": this.user_code, "userdept": '', "userpwd": '', "userrole": '', "userstatus": '' },
                nagarpalika:{"name":'',"address":'',"pincode":'',"contact":'',"status":1,"logo":"","default_lat":"","default_lng":""},
                question:{"survey_id":'1',"questiontype":'1',"marks":'',"que":""},
                survey: { "name": '', "status": 1,"nagarpalika_id":0 },
                category: { "name": '', "status": 1 },
                dept: { "name": '', "status": 1 },
                room: { "name": '', "status": 1 },
                visitor: { "name": '', "firmname": '', "contact": '', "purpose": '', 'schedule_date': new Date().toLocaleString() },
                product: { "id":'',"code": '', "unit": '', "name": '', "description": '' ,'minqty':0},
                vendor: { "id":'',"name": '', "mobile_no": '', "address": ''},
                iscalled: 0,
                departments: [], 
                rooms: [],
                products: [],
                gatepass: [],
                myInstance: null 
            }
        },
        watch: {
            initialData: {
                immediate: true,
                handler(newVal) {
                    if (newVal) {
                        this.product = { ...newVal };
                        this.vendor = { ...newVal };
                    }
                }
            }
        },
        methods: {
            acceptNumber() {
                var x = this.visitor.contact.replace(/\D/g, '').match(/(\d{0,10})/);
                this.visitor.contact = x[1];
                if (this.visitor.contact.length == 10) {
                    var self = this;
                    axios.post('getVisitor', { contact_no: this.visitor.contact }).then(function (response) {
                        if (response.data.is == 1)
                            self.visitor = response.data.user;
                        window.scrollTo(0, 0);
                    });
                }
            },
            getBasics() {
                
            },
             
            submitForm() {
                if (this.isEditMode) {
                    this.$emit('edit-product', this.product);
                } else {
                    this.$emit('add-product', this.product);
                }
                // this.closeModal();
               
            },
            submitVendorForm() {
                if (this.isEditMode) {
                    this.$emit('edit-vendor', this.vendor);
                } else {
                    this.$emit('add-vendor', this.vendor);
                }
                // this.closeModal();
               
            },
            save_product() {
                var self = this;
                const formData = new FormData();
                formData.append('code', self.product.code);
                formData.append('unit', self.product.unit);
                formData.append('name', self.product.name);
                formData.append('description', self.product.description);
                axios.post('addproduct', formData)
                    .then(response => {
                        var msg = response.data.msg;
                        var isSuccess = response.data.status;
                        self.products = response.data.products;
                        self.$swal(msg);
                        if (isSuccess == 1) {
                            $("#productAdd").modal("toggle");
                            self.$emit('getProductData', self.products);
                        }
                    })
                    .catch(error => {
                        console.log('Error on Authentication: ' + error);
                    });
            },
            save_dept() {
                var self = this;
                const formData = new FormData();
                formData.append('deptname', self.dept.name);
                formData.append('deptstatus', self.dept.status);
                axios.post('adddepartment', formData)
                    .then(response => {
                        var msg = response.data.msg;
                        var isSuccess = response.data.status;
                        self.departments = response.data.departments;
                        self.$swal(msg);
                        if (isSuccess == 1) {
                            $("#departmentAdd").modal("toggle");
                            self.$emit('getDeptData', self.departments);
                        }
                    })
                    .catch(error => {
                        console.log('Error on Authentication: ' + error);
                    });
            },
            save_survey() {
                var self = this;
                const formData = new FormData();
                formData.append('name', self.survey.name);
                formData.append('status', self.survey.status);
                formData.append('nagarpalika_id', self.survey.nagarpalika_id);
                axios.post('addsurvey', formData)
                    .then(response => {
                        var msg = response.data.msg;
                        var isSuccess = response.data.status;
                        self.survey = response.data.survey;
                        self.$swal(msg);
                        if (isSuccess == 1) {
                            $("#surveyAdd").modal("toggle");
                            self.$emit('getSurveyData', self.survey);
                        }
                    })
                    .catch(error => {
                        console.log('Error on Authentication: ' + error);
                    });
            },
            save_category() {
                var self = this;
                const formData = new FormData();
                formData.append('categoryname', self.category.name);
                formData.append('categorystatus', self.category.status);
                axios.post('addcategory', formData)
                    .then(response => {
                        var msg = response.data.msg;
                        var isSuccess = response.data.status;
                        self.categories = response.data.categories;
                        self.$swal(msg);
                        if (isSuccess == 1) {
                            $("#categoryAdd").modal("toggle");
                            self.$emit('getDeptData', self.categories);
                        }
                    })
                    .catch(error => {
                        console.log('Error on Authentication: ' + error);
                    });
            },
             upload(event){
                    this.nagarpalika.logo = event.target.files[0];
             },
            save_nagarpalika() {
                var self = this;
                const formData = new FormData();
                formData.append('name', self.nagarpalika.name);
                formData.append('address', self.nagarpalika.address); 
                formData.append('contact', self.nagarpalika.contact);
                formData.append('status', self.nagarpalika.status);
                formData.append('logo', self.nagarpalika.logo);
                formData.append('default_lat', self.nagarpalika.default_lat);
                formData.append('default_lng', self.nagarpalika.default_lng);
                formData.append('usercode', self.nagarpalika.usercode);
                
                
                axios.post('addnagarpalika', formData)
                    .then(response => {
                        var msg = response.data.msg;
                        var isSuccess = response.data.status;
                        self.nagarpalikas = response.data.nagarpalikas;
                        self.$swal(msg);
                        if (isSuccess == 1) {
                            $("#nagarpalikaAdd").modal("toggle");
                            self.$emit('getNagarpalikaData', self.nagarpalikas);
                        }
                    })
                    .catch(error => {
                        console.log('Error on Authentication: ' + error);
                    });
            },
            save_question() {
                var self = this;
                const formData = new FormData();
                formData.append('survey_id', self.question.survey_id);
                formData.append('question', self.question.que);
                formData.append('questiontype', self.question.questiontype);
                formData.append('marks', self.question.marks);
                axios.post('addquestion', formData)
                    .then(response => {
                        var msg = response.data.msg;
                        var isSuccess = response.data.status;
                        self.questions = response.data.questions;
                        self.$swal(msg);
                        if (isSuccess == 1) {
                            $("#questionAdd").modal("toggle");
                            self.$emit('getQuestionData', self.questions);
                        }
                    })
                    .catch(error => {
                        console.log('Error on Authentication: ' + error);
                    });
            },
            save_user() {
                var self = this;
                const formData = new FormData();
                formData.append('username', self.user.username);
                formData.append('usercontact', self.user.usercontact); 
                formData.append('usercode', self.user.usercode);
                formData.append('userdept', self.user.userdept);
                formData.append('userpwd', self.user.userpwd);
                formData.append('userrole', self.user.userrole);
                formData.append('userstatus', self.user.userstatus);
                axios.post('adduser', formData)
                    .then(response => {
                        var msg = response.data.msg;
                        var isSuccess = response.data.status;
                        self.users = response.data.users;
                        self.$swal(msg);
                        if (isSuccess == 1) {
                            $("#userAdd").modal("toggle");
                            self.$emit('getUserData', self.users);
                        }
                    })
                    .catch(error => {
                        console.log('Error on Authentication: ' + error);
                    });
            },
        }
    }
</script>