<template>

  <!-- Page Sidebar Ends-->
  <div class="page-body">
  
    <PageTitle   title="Tree Survey" path="frontdash" />
    <!-- Container-fluid starts-->
    <div class="container-fluid" v-show='!isCapture && !isLocation' ref="surveyContainer">
      <div class="row size-column">

        <div class="col-xxl-9 box-col-12">
               <div class="card ">  
                    <div class="card-body">  
                  <div class="row">
                   
                
             <div class="col-xl-6 col-sm-12 col-12" >
                <label class="form-label"><span class="f-w-500 f-14">1)Remark</span></label>
                <input class="form-control" type="text" v-model="tree.remark" >
            </div>  
                        
            <div class="col-xl-6 col-sm-12 col-12"   >
                 <label class="form-label"><span class="f-w-500 f-14">2)Local Name</span></label>
                 <select  class="form-control" id="tree_names" v-model="tree.local_name" >
                     <option  v-for="(item ,index)  in treenames"   :value="item.marathi_name">{{item.marathi_name}}</option>
                 </select>
                  
              </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">3)Comman Name</span></label>
                  <select  class="form-control" id="c_tree_names" v-model="tree.comman_name" >
                     <option  v-for="(item ,index)  in treenames"   :value="item.hindi_name">{{item.hindi_name}}</option>
                 </select>
                 
                <!--<input class="form-control" type="text" v-model="tree.comman_name" >-->
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">4)Botinical Name</span></label> 
                  <select  class="form-control" id="b_tree_names" v-model="tree.botnical_name" >
                     <option  v-for="(item ,index)  in treenames"   :value="item.botnical_name">{{item.botnical_name}}</option>
                 </select>
                 <!--<input class="form-control" type="text" v-model="tree.botnical_name" >  -->
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">5)Other Name</span></label>
                <input class="form-control" type="text" v-model="tree.other_name" >
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">6)Tree Location</span></label>
                <button @click="getLocation"  class=" badge bg-primary">Get Location</button>
                <input  class="form-control" type="text" v-model="tree.tree_location_lat" >
              
                <div id="locError" class="text-danger"></div>
                
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class=":'',f-w-500 f-14">7)Girth</span></label>
                <input class="form-control" type="number" v-model="tree.girth" >
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">8)Height</span></label>
                <input class="form-control" type="number" v-model="tree.height" >
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">9)Conopy Diameter</span></label>
                <input class="form-control" type="number" v-model="tree.diameter" >
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">10)Tree Condition</span></label>
                 <select  class="form-control" v-model="tree.tree_condition" >
                     <option disabled selected value="NA">---PLEASE SELECT---</option>
                     <option value="Healthy">Healthy</option>
                     <option value="Average">Average</option>
                     <option value="Dead">Dead</option>
                 </select> 
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">11)Tree Balance</span></label>
                 <select  class="form-control" v-model="tree.tree_balance" >
                     <option disabled selected value="NA">---PLEASE SELECT---</option>
                     <option value="Balance">Balance</option>
                     <option value="In-Balance">In-Balance</option> 
                 </select>   
            </div>
             <div class="col-xl-6 col-sm-12 col-12"   style="display:none;" >
                <label class="form-label"><span class="f-w-500 f-14">12)Other Remark</span></label>
                <input class="form-control" type="text" v-model="tree.other_remark" >
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   style="display:none;" >
                <label class="form-label"><span class="f-w-500 f-14">13)Dist. From Prev. Tree</span></label>
                <input class="form-control" type="number" v-model="tree.dist_from_prev" >
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">12) Ownership</span></label>
                 <select  class="form-control" v-model="tree.ownership" >
                     <option disabled selected value="NA">---PLEASE SELECT---</option>
                     <option value="Private">Private</option>
                     <option value="Government">Government</option> 
                     <option value="On Road">On Road</option> 
                     <option value="On Divider">On Divider</option> 
                     <option value="Semi Government">Semi Government</option> 
                 </select>  
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   style="display:none;" >
                <label class="form-label"><span class="f-w-500 f-14">13)Special Comment</span></label>
                <input class="form-control" type="text" v-model="tree.spacial_comment" >
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">13) Society Name</span></label>
                <input class="form-control" type="text" v-model="tree.society_name" >
            </div>
             <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">14) Road Name</span></label>
                <input class="form-control" type="text" v-model="tree.road_name" >
            </div>
             <div class="col-xl-6 col-sm-12 col-12"  style="display:none;"  >
                <label class="form-label"><span class="f-w-500 f-14">15) Bird Nest</span></label>
                <input class="form-control" type="text" v-model="tree.bird_nest" >
            </div>
             <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">15) Tree Image</span></label><br>
                <img  v-if="tree.image && tree.image!=''" :src="tree.image" @click="caputureImage()" width="100"/>
               <img  v-else  src="../../assets/img_upload.png" width="100" @click="caputureImage()"/>  
            </div>
            
              
          </div>
            </div>
              <div class="card-footer">  
              <div class="mb-2">
                  <h6 v-show="!isOnline" class="text-danger">You Are Offline,Data will be saved locally.</h6>
              </div>
                <div class="col-12">
                        <button class="btn btn-primary" @click="save_survey">Submit Survey</button>
                        &nbsp;&nbsp; <button class="btn btn-danger" @click="clear_survey">Clear Inputs</button>
                    </div>
                    <div class="text-info" id="process_lbl"></div>
            </div>
                 </div>
                 </div>
      </div>
      </div>
   
    
  <CaptureImage v-if='isCapture' @backBtn="backBtn" @setImage="setImage"/>
  <!--<WebcamCapture v-if='isCapture' @backBtn="backBtn" @setImage="setImage"/>-->
  <!--<LocationPicker v-show='isLocation' @backBtnLoc="backBtnLoc" @setLocation="setLocation" :last_pins="last_pins" :userDetails="userDetails"/>-->
                <div :class="(isLocation)?'show':'hide'" >
                        <LocationPicker @backBtnLoc="backBtnLoc" @setLocation="setLocation" :last_pins="last_pins" :userDetails="userDetails"/>
                </div>
  </div>
</template>
<style scooped>
    .form-control{
                margin-bottom: 15px;
                padding: 1px 7px !important;
                border-color: black;
    }
    .card-body{
                
    }
    .select2-container .select2-selection--single{
        height: 30px !important;
         padding:  1px 7px !important;
    }
    .hide{
        display: none;
    }
    .show{
        display: block;
    }
    
</style>
<script>
import PageTitle from '@/components/PageTitle.vue';
import axios from 'axios';
import WebcamCapture from '@/components/WebcamCapture.vue';
import LocationPicker from '@/components/LocationPicker.vue';
import CaptureImage from '@/components/CaptureImage.vue';
import { addTreeRecord, getAllTrees, addSurveyRecord ,clearTrees} from '@/dexie';
import { ref, onMounted } from 'vue';

export default {
  name: 'NewSurvey',
  components: {
        PageTitle ,WebcamCapture,LocationPicker,CaptureImage,addTreeRecord,getAllTrees,addSurveyRecord
    },
    beforeRouteLeave(to, from, next) {
            
            this.isLocation=0;
            this.isCapture=0;
            next();
    },
    computed:{
            showSection(){
                return  this.showCreateSection;
                }
        },
      mounted() {
            if (this.$route.query.q) {
                this.getSurveyQues(this.$route.query.q);
            }else {
                
                        this.getBasics();
                  
            }
           
        }, 
        data() {
        return { 
          tree: {'iddate':this.today(),'local_name':'','comman_name':'','botnical_name':'','other_name':'','tree_location_lat':'','tree_location_lng':'','girth':'','height':'','diameter':'','tree_condition':'NA','tree_balance':'NA','remark':'','other_remark':'','dist_from_prev':'','ownership':'NA','spacial_comment':'','society_name':'','road_name':'','bird_nest':''}, 
          survey_details: {name:''},
          isCapture:false,
          isLocation:false,
          isSubmitted:false,
          treenames:[],
          treebotnical_name:[],
          last_pins:[],
          prev_details:{'society_name':'','road_name':''},
          userDetails:{'kmlurl':''},
          isDataLoaded:false ,
          location:{
                latitude: 18.57094038048649,
                longitude: 73.81621311358407
          },
          isOnline:  navigator.onLine, // Initial status
        }
    },
     beforeUnmount() {
    // Clean up event listeners
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  setup(){
       
    const newRecord = ref({ token: '', data: '' });  
    
         const loadTreeRecords = async () => {
           const data_here= await getAllTrees();
               if(data_here.length>0){
                    //self.treenames  = data_here[0].data;
                    for (var i = 0; i < data_here[0].data.length; i++) {
                        console.log(data_here[0].data[i]);
                    }
               }
     }
     
  }, 
    methods: {
        today(){
            var today = new Date()
            var month=today.getMonth()+1;
            var day=today.getDate();
            
            var hr=today.getHours();
            var min=today.getMinutes();
            var sec=today.getSeconds();
            
            var now_date = (today.getFullYear() + '-' + ((month<10)?'0'+month:month) + '-' + ((day<10)?'0'+day:day));//today.getDate());
            var now_time = (((hr<10)?'0'+hr:hr) + ":" +((min<10)?'0'+min:min) + ":" + ((sec<10)?'0'+sec:sec) ).toString();
            
            return now_date+' '+now_time;
        },
         updateOnlineStatus() {
          this.isOnline = navigator.onLine;
        },
        clear_survey(){
            var self=this;
            $("#tree_names").val('').trigger('change');
             this.tree= {'iddate':this.today(),'local_name':'','comman_name':'','botnical_name':'','other_name':'','tree_location_lat':'','tree_location_lng':'','girth':'','height':'','diameter':'','tree_condition':'NA','tree_balance':'NA','remark':'','other_remark':'','dist_from_prev':'','ownership':'NA','spacial_comment':'','society_name':'','road_name':'','bird_nest':''};
             this.isSubmitted
             
             
                                          $("#b_tree_names").val(self.tree.botnical_name).select2();
                                          $("#c_tree_names").val(self.tree.hindi_name).select2(); 
                                          $("#tree_names").val(self.tree.local_name).select2(); 
                                          
                                          
             
             this.isSubmitted=false;
             
            if(this.prev_details.society_name)
                this.tree.society_name=this.prev_details.society_name;
            
            if(this.prev_details.road_name)
             this.tree.road_name=this.prev_details.road_name;
             
        },
     async save_survey() {
          
          if(!this.isSubmitted || this.isSubmitted){  // for double upload
              this.isSubmitted=true;
              if(this.tree.local_name.length==0)
                  this.$swal("Please Enter Local Name.");
              else{
                //$(".loader-wrapper").show(); 
                
                $("#process_lbl").html("Saving In Background");
                
                    var self = this;
                             const blob = self.dataURLtoBlob(this.tree.image);
                                    
                    //07.09.2024
                    if(this.isOnline){//11.09.2024
                            self.prev_details.society_name=self.tree.society_name;
                            self.prev_details.road_name=self.tree.road_name;
                            
                            localStorage['latLong']=self.tree.tree_location_lat;
                            
                                 
                                const formData = new FormData();
                                formData.append('image', blob, 'captured_photo.png'); 
                                formData.append('ques', JSON.stringify(self.tree)); 
                                
                                
                            await axios.post('saveTreeSurvey',formData).then(function (response) {
                               
                               //self.$swal(response.data.msg);
                                $("#process_lbl").html(response.data.msg);
                                
                               self.surveys  = response.data.surveys ;   
                                $(".loader-wrapper").hide();
                               
                              //  window.scrollTo(0, 0);
                            }).catch(async (error) => { 
                                   if (error) {
                                       console.log(error)
                                       if(!error.response){
                                           self.tree.iddate=this.today();
                                             const id = await addSurveyRecord({ token:localStorage['token'], data: JSON.stringify(self.tree),blob:blob });
                                             if(id>0){
                                                     $("#process_lbl").html(self.tree.iddate+") Data Saved Offline");//self.$swal("Data Saved Offline");
                                                   this.isSubmitted=false;
                                             }
                                             else{
                                                      $("#process_lbl").html("Failed To Save Offline");//self.$swal("Failed To Save Offline");
                                                    this.isSubmitted=false;
                                             }      
                                                     $(".loader-wrapper").hide();
                                       }
                                   }  
                              });
                    }else{
                      //  alert(localStorage['token']);
                      const id = await addSurveyRecord({ token:localStorage['token'], data: JSON.stringify(self.tree),blob:blob });
                         if(id>0){
                               self.$swal("Data Saved Offline");
                               this.isSubmitted=false;
                         }
                         else{
                                self.$swal("Failed To Save Offline");
                                this.isSubmitted=false;
                         }      
                                 $(".loader-wrapper").hide();
                                 
                                 
                    }
              }
          }
        },
        setRadio(what,item){
            item.ans=what;
        },
        setImage(value){ 
            this.tree.image=value;
             this.isCapture=false; 
             this.scrollToBottom();
          
        },
        scrollToBottom(){
                  this.$nextTick(() => {
                    const container = this.$refs.surveyContainer;
                    if (container) {
                      container.scrollTop = container.scrollHeight;
                    }
                  });
        },
        backBtn(){  
             this.isCapture=false;
        },
        caputureImage(){
            this.isCapture=true;
        },
       
     async  getBasics() {
            var self = this;
           
           if(this.isOnline){
                    axios.post('getReportBasics',{location:this.location}).then(function (response) {
                        self.treenames  = response.data.treenames ;     
                        self.last_pins  = response.data.last_pins ;    
                        self.userDetails=response.data.userdetails;
                        
                        self.tree.society_name=response.data.last_survey.society_name;     
                        self.tree.road_name=response.data.last_survey.road_name;     
                        
                        self.prev_details.society_name=self.tree.society_name;
                        self.prev_details.road_name=self.tree.road_name;
                       
                        clearTrees();
                        addTreeRecord({ data: JSON.stringify(self.treenames) });
                       // window.scrollTo(0, 0);
                    }).then(()=>{
                                  $("#tree_names").select2().on('change', function (event) {
                                      
                                    const selectedProduct = self.treenames.find(item => item.marathi_name === event.target.value);
                                      if (selectedProduct) {
                                          self.tree.local_name= selectedProduct.marathi_name; 
                                          self.tree.comman_name= selectedProduct.hindi_name;
                                          //self.tree.other_name= selectedProduct.english_name; 
                                          self.tree.botnical_name= selectedProduct.botnical_name; 
                                          
                                          $("#b_tree_names").val(self.tree.botnical_name).select2();
                                          $("#c_tree_names").val(self.tree.comman_name).select2(); 
                                      }
                                    
                            }); 
                            
                            
                               $("#b_tree_names").select2().on('change', function (event) {
                                      
                                    const selectedProduct = self.treenames.find(item => item.botnical_name === event.target.value);
                                      if (selectedProduct) {
                                          self.tree.local_name= selectedProduct.marathi_name; 
                                          self.tree.comman_name= selectedProduct.hindi_name;
                                          //self.tree.other_name= selectedProduct.english_name; 
                                          self.tree.botnical_name= selectedProduct.botnical_name; 
                                          
                                          $("#tree_names").val(self.tree.local_name).select2();
                                          $("#c_tree_names").val(self.tree.comman_name).select2(); 
                                           
                                      }
                                    
                            }); 
                            
                             $("#c_tree_names").select2().on('change', function (event) {
                                      
                                    const selectedProduct = self.treenames.find(item => item.hindi_name === event.target.value);
                                      if (selectedProduct) {
                                          self.tree.local_name= selectedProduct.marathi_name; 
                                          self.tree.comman_name= selectedProduct.hindi_name;
                                          //self.tree.other_name= selectedProduct.english_name; 
                                          self.tree.botnical_name= selectedProduct.botnical_name; 
                                          
                                          
                                          $("#tree_names").val(self.tree.local_name).select2();
                                          $("#b_tree_names").val(self.tree.botnical_name).select2(); 
                                            
                                          //alert(selectedProduct.marathi_name+'---'+selectedProduct.hindi_name+'---'+selectedProduct.botnical_name);
                                      }
                                    
                            }); 
                            
                            
                            
                                 // $("#tree_bot_names").select2();
                    }); 
           }else{
               //self.treenames  = JSON.parse(getAllTrees()[0]);
               const data_here= await getAllTrees();
               if(data_here.length>0){
                    self.treenames  = JSON.parse(data_here[0].data);
                     $("#tree_names").select2().on('change', function (event) {
                                    const selectedProduct = self.treenames.find(item => item.marathi_name === event.target.value);
                                      if (selectedProduct) {
                                          self.tree.local_name= selectedProduct.marathi_name; 
                                          self.tree.comman_name= selectedProduct.hindi_name;
                                          self.tree.other_name= selectedProduct.english_name; 
                                          self.tree.botnical_name= selectedProduct.botnical_name; 
                                      }
                     });
               }
                
               //console.log(data_here[0].data);
           }
        },
      getSurveyQues(servey_id) {
            var self = this;
            const blob = self.dataURLtoBlob(this.photo);
            axios.post('getFrontSurveyQues',{servey_id:servey_id}).then(function (response) {
                self.ques  = response.data.ques ;   
                self.userDetails  = response.data.userDetails ;   
                self.survey_details  = response.data.survey_details ;   
               
            });
        },
        getLocation(){
            navigator.geolocation.getCurrentPosition(this.success, this.error);
        },
        
        error(err) {
            $("#locError").html(err.message);
            this.isLocation=true;
            //this.tree.tree_location_lat=err.message;
            console.log(err);
        }, 
        success(position) {
           // const latitude  = position.coords.latitude;
           // const longitude = position.coords.longitude;
          //  this.tree.tree_location_lat=latitude+","+longitude;
            this.isLocation=true;
            $("#locError").html('');
        },
        
        

        dataURLtoBlob(dataURL) {
          const byteString = atob(dataURL.split(',')[1]);
          const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          return new Blob([ab], { type: mimeString });
        }, 
        setLocation(value){ 
           const latitude  = value.lat;
           const longitude = value.lng;
          this.tree.tree_location_lat=latitude+","+longitude;
           
           this.isLocation=false; 
        },
        backBtnLoc(){  
             this.isLocation=false;
        },
        
    }
}
</script>
