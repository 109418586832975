<template>

    <!-- Page Sidebar Ends-->
    <div class="page-body">
        <PageTitle title="Users list"  />
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header"> 
                                <div class=" row">
                                  
                                    <div class="col-md-4 col-12 position-relative"  >
                                        <label class="form-label">Filter Nagarpalika</label>
                                        <select class="form-select" required="" v-model="filter.np"  @change="getAgents($event)">
                                            <option selected=""   disabled="" value="0">Choose...</option>
                                            <template v-for="(item ,index)  in nagarpalika_list" :key="item.id">
                                                  <option :value="item.id">{{item.name}}</option>   
                                            </template> 
                                        </select>
                                    </div>
                                     <div class="col-md-4 col-12 position-relative"  >
                                         
                                     </div>
                                     <div class="col-md-4 col-12 position-relative"  >
                                         <button class="btn btn-secondary" type="button" data-bs-toggle="modal"
                                data-original-title="test" data-bs-target="#userAdd">Add User</button>
                                     </div>
                                </div> 
                            
                        </div>
                        
                        <div class="card">
                              <div class="card-body">
                                 <div class="col-xl-12 col-sm-12">
                                    <Bar :data="chart_data" :options="options" />
                                    
                                 </div>
                            </div>
                        </div>
                        <div class="card-body badge-spacing">
                            <div class="table-responsive custom-scrollbar">
                                <table class="table display" id="basic-1">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>Username</th>
                                            <th>Name</th>
                                            <th>Contact</th>
                                            <th>Nagarpalika</th> 
                                            <th>Password</th> 
                                            <th>Role</th>
                                            <th>Status</th> 
                                            <th>Action</th>
                                
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  v-for="(item ,index)  in filter_agent_list" :key="item.id" >
                                            <td>{{index+1}}</td>
                                            <td>{{item.empcode}}</td>
                                            <td>{{item.empname}}</td>
                                            <td>{{item.empcontact}}</td>
                                            <td>{{item.np_name}}</td> 
                                            <td>{{item.emppwd}}</td> 
                                            <td>{{roles[item.emprole]}}</td> 
                                            <td>{{(item.empstatus==1)?'Active':'De-Active'}}</td>
                                            <td>
                                                <ul class="action">
                                                    <li class="edit" @click="edit_user(item.id)"><i class="icon-pencil-alt"></i></li>
                                                    <li class="delete" @click="remove_user(item.id)"><i class="icon-trash"></i></li>
                                                </ul>
                                            </td> 
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- footer   start-->

    <ModalsPart v-if="!['login'].includes($route.name)"  :user_code="user_code" :nagarpalika_list="nagarpalika_list" :userDetails="userDetails" @getUserData="updateUserData"/>
</template>

<script>
// @ is an alias to /src 
import PageTitle from '@/components/PageTitle.vue';
import ModalsPart from '@/components/Modals.vue';
import axios from 'axios';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,ArcElement
} from 'chart.js'
import { Bar,Pie } from 'vue-chartjs'
ChartJS.register(CategoryScale,ArcElement, LinearScale, BarElement, Title, Tooltip, Legend)


const config = {
            headers: {
               "Content-Type": "application/x-www-form-urlencoded"
            },
            responseType: 'json'
         };
export default {
    name: 'users', 
    components: {
        PageTitle, ModalsPart
    }, mounted() {
        this.getBasics();

    }, data() {
        return { 
          users: [],
           x_values: [],
           y_values: [],
          roles: ["","System Admin","Nagarpalika Admin","Nagarpalika HOD","Nagarpalika Employee","Nagarpalika Admin"],
          nagarpalika_list:[],
          userDetails:[],
          filter_agent_list:[],
          filter:{'np':'','agent':'','fromdate':'','todate':''},
          chart_data: {
                labels: this.x_values,
                datasets: [{ data: this.purchaseStock},{data: this.saleStock }]
              },
               options: {
  maintainAspectRatio: false,
                responsive: true
              }
        }
    },
    methods: { 
        updateUserData(value) {
            this.users = value;
        },
        getAgents(event){ 
             var np_id=event.target.value;
              this.filter_agent_list=this.users.filter(item => item.empdept === np_id);
              for(var i=0;i<this.filter_agent_list.length;i++){
                  this.x_values.push( this.filter_agent_list[i].empname );
                  this.y_values.push( this.filter_agent_list[i].total_surveys );
                  
                  
              }
             this.chart_data= {labels: this.x_values,datasets: this.y_values };
              
        },
        getBasics() {
            var self = this;
            axios.post('getUsers').then(function (response) {
                self.users = response.data.users; 
                self.filter_agent_list=self.users;
                self.nagarpalika_list = response.data.nagarpalikas; 
                self.userDetails = response.data.userDetails; 
                self.user_code = response.data.user_code; 
                window.scrollTo(0, 0);
            });
        },
        remove_user(id) {
            var self = this;
            axios.post('removeUser', {id:id}).then(function (response) {
                self.$swal(response.data.msg);
                
                if(response.data.is==1){
                    self.users = response.data.users; 
                    self.nagarpalika_list = response.data.nagarpalikas; 
                    
                }
                window.scrollTo(0, 0);
            });
        }
       
    }
}
</script>
