<template>
    
 
  
  <nav  v-if="!['login'].includes($route.name)">
    <Header />
  </nav>   
  
  <router-view/>
  
   <FooterPart  v-if="!['login'].includes($route.name)"/>
</template>
<style>
    .dt-button{
       background: darkgreen;
    color: white;
    padding: 1px 5px;
    border-radius: 5px;
    margin-left: 5px; 
    }
    .page-body{
            margin-bottom: 100px;
    margin-top: 57px;
    }
    .tap-top{
       display: none !important; 
    }
    th{
            text-transform: uppercase !important;
    text-align: center;
    }
    .table thead th{
        background: #656565;
    }
    .dataTables_filter label input{
      border: 1px solid black !important;
    }
</style>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'; 
import FooterPart from '@/components/Footer.vue'; 
import ModalsPart from '@/components/Modals.vue';
  import axios from 'axios';

export default {
  name: 'App',
  components: {
    Header,FooterPart,ModalsPart 
  },
      
  
}

</script>