<template>
    <button class="btn bt-danger" @click="backBtnPress()"><i class="fa fa-arrow-left"></i> Go Back</button>
  <div class="camera-container">
    <video ref="video" autoplay playsinline></video>
    <canvas ref="canvas" style="display: none;"></canvas>
    <div class="controls">
      <button @click="backBtnPress" class="capture-button1"><i class="fa fa-arrow-left"></i> Go Back</button>
      <button @click="capturePhoto" class="capture-button"><i class="fa fa-camera"></i> Capture</button>
      <button v-if="capturedImage" @click="retakePhoto" class="retake-button">Retake</button>
      <img v-if="capturedImage" :src="capturedImage" class="captured-image" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      capturedImage: null,
    };
  },
  mounted() {
    this.startCamera();
    $(".page-body").css("margin-top","0");
  },
  methods: {
      backBtnPress(){
        $(".page-body").css("margin-top","57px");
        this.$emit('backBtn',"") ;
    },
    async startCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({  audio: false,
          video: {
            facingMode: 'environment'
          } });
        this.$refs.video.srcObject = stream;
      } catch (error) {
        console.error('Error accessing the camera: ', error);
      }
    },
    capturePhoto() {
      const canvas = this.$refs.canvas;
      const video = this.$refs.video;

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

     // this.capturedImage = canvas.toDataURL('image/png');
        $(".page-body").css("margin-top","57px");
       this.$emit('setImage',canvas.toDataURL('image/png')) ;
    },
    retakePhoto() {
      this.capturedImage = null;
    },
  },
  beforeDestroy() {
    const stream = this.$refs.video.srcObject;
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
  },
};
</script>

<style>
.camera-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
    z-index: 999;
    top: 0;
}

video {
  width: 100%;
  height: 100vh;
  height: auto;
  border-radius: 8px;
  display: block;
}

.controls {
  margin-top: 20px;
  text-align: center;
  position: fixed;
}
.capture-button1  {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
  border: none;
  background-color: #ff0000;
  color: white;
  border-radius: 5px;
}

.capture-button, .retake-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 5px;
  border: none;
  background-color: #159200;
  color: white;
  border-radius: 5px;
}

.captured-image {
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
  border-radius: 8px;
}
</style>
