<template>

  <!-- Page Sidebar Ends-->
  <div class="page-body">
  
    <PageTitle   title="Tank Survey" path="frontdash" />
    <!-- Container-fluid starts-->
    <div class="container-fluid" v-show='!isCapture && !isLocation' ref="surveyContainer">
      <div class="row size-column">

        <div class="col-xxl-9 box-col-12">
               <div class="card ">  
                    <div class="card-body">  
                  <div class="row">
                   
                
             <div class="col-xl-6 col-sm-12 col-12" >
                <label class="form-label"><span class="f-w-500 f-14">1)सेप्टिक टॅंक सर्व्हे	नागरिक/ आस्थापनेचे नाव (Name of Citizen / Hotel / Office / School / Society/Other)</span></label>
                <input class="form-control" type="text" v-model="tank.name" >
            </div>  
                        
            <div class="col-xl-6 col-sm-12 col-12"   >
                 <label class="form-label"><span class="f-w-500 f-14">2)वॉर्ड नं. (Ward No)</span></label>
                  <input class="form-control" type="number" v-model="tank.ward" > 
              </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">3)घर क्रमांक ( Property Number)</span></label>
                   <input class="form-control" type="text" v-model="tank.property" >
                 
                <!--<input class="form-control" type="text" v-model="tank.comman_name" >-->
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">4)फोन नंबर (Phone Number)</span></label> 
                  <input class="form-control" type="number" v-model="tank.phone" >
                 <!--<input class="form-control" type="text" v-model="tank.botnical_name" >  -->
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">5)पत्ता(Address)</span></label>
                <input class="form-control" type="text" v-model="tank.address" >
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">6)Tank Location</span></label>
                <button @click="getLocation"  class=" badge bg-primary">Get Location</button>
                <input  class="form-control" type="text" v-model="tank.tank_location_lat" >
              
                <div id="locError" class="text-danger"></div>
                
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class=":'',f-w-500 f-14">7)आस्थापनेचा प्रकार(Establishment)</span></label>
                 <select  class="form-control" v-model="tank.establishment" >
                     <option disabled selected value="NA">---PLEASE SELECT---</option> 
                     <option value="Individual House">Individual House</option>
                     <option value="Apartment in Residential Building">Apartment in Residential Building</option>
                     <option value="Community Toilet (CT)">Community Toilet (CT)</option>
                     <option value="Public Toilet (PT)">Public Toilet (PT)</option>
                     <option value="Shop/Commercial Unit">Shop/Commercial Unit</option>
                     <option value="Hotel">Hotel</option>
                     <option value="Residential + Commercial">Residential + Commercial</option>
                     <option value="Hospital">Hospital</option>
                     <option value="School">School</option>
                     <option value="Government office">Government office</option> 
                 </select>  
                
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">8)सोसायटी मधील घरांची संख्या (Number of Homes In Society) </span></label>
                <input class="form-control" type="number" v-model="tank.society" >
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">9)आपल्याकडे सेप्टिक टॅंक ची स्थापना केली आहे का? (Type of Sanitation Connection)</span></label>
                 <select  class="form-control" v-model="tank.sanitation" >
                     <option disabled selected value="NA">---PLEASE SELECT---</option> 
                     <option value="Septic Tank without Soak Pit">Septic Tank without Soak Pit</option>
                     <option value="Septic Tank with Soak Pit">Septic Tank with Soak Pit</option> 
                 </select> 
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">10)सेप्टिक टॅंक उपसा(Septic Tank Emptied At)</span></label>
                 <select  class="form-control" v-model="tank.empied" >
                     <option disabled selected value="NA">---PLEASE SELECT---</option>
                     <option value="Never Emptied">Never Emptied(कधीच नाही)</option>
                     <option value="In Last 6 months">In Last 6 months</option>
                     <option value="6 to 12 Months">6 to 12 Months(मागील एका वर्षात)</option>
                     <option value="1 to 3 years">1 to 3 years(मागील तीन वर्षात)</option>
                     <option value="More than 3 years">More than 3 yearss ((मागील तीन वर्षापेक्षा जास्त)  </option> 
                 </select> 
                 
            </div> 
             <div class="col-xl-6 col-sm-12 col-12"   style="display:none;" >
                <label class="form-label"><span class="f-w-500 f-14">12)Other Remark</span></label>
                <input class="form-control" type="text" v-model="tank.other_remark" >
            </div>
            <div class="col-xl-6 col-sm-12 col-12"   style="display:none;" >
                <label class="form-label"><span class="f-w-500 f-14">13)Dist. From Prev. tank</span></label>
                <input class="form-control" type="number" v-model="tank.dist_from_prev" >
            </div>
            <div class="col-xl-6 col-sm-12 col-12"    style="display:none;" >
                <label class="form-label"><span class="f-w-500 f-14">12) Ownership</span></label>
                 <select  class="form-control" v-model="tank.ownership" >
                     <option disabled selected value="NA">---PLEASE SELECT---</option>
                     <option value="Private">Private</option>
                     <option value="Government">Government</option> 
                     <option value="On Road">On Road</option> 
                     <option value="On Divider">On Divider</option> 
                     <option value="Semi Government">Semi Government</option> 
                 </select>  
            </div>
           
             <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">11) Tank Image</span></label><br>
                <img  v-if="tank.image && tank.image!=''" :src="tank.image" @click="caputureImage()" width="100"/>
               <img  v-else  src="../../assets/img_upload.png" width="100" @click="caputureImage()"/>  
            </div>
            
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">12)हरित पथदिवे (Solar Panels)</span></label>
                 <select  class="form-control" v-model="tank.solar" @change="select_solar()">
                     <option disabled selected value="NA">---PLEASE SELECT---</option>
                     <option value="Yes">Yes</option>
                     <option value="No">No</option>
                 </select>  
            </div> 
            
             <div class="col-xl-6 col-sm-12 col-12" v-if="isSolar"  >
                <label class="form-label"><span class="f-w-500 f-14">12.1) Solar Image</span></label><br>
                <img  v-if="tank.solar_pic && tank.solar_pic!=''" :src="tank.solar_pic" @click="caputureSolarImage()" width="100"/>
               <img  v-else  src="../../assets/img_upload.png" width="100" @click="caputureSolarImage()"/>  
            </div>
            <div class="col-xl-6 col-sm-12 col-12" v-if="isSolar" >
                <label class="form-label"><span class="f-w-500 f-14">12.2)Remark</span></label>
                <input class="form-control" type="text" v-model="tank.solar_remark" >
            </div>
            
            
            
            <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">13)पावसाचे पाणी साठवण प्रणाली  (Rain Water Harvesting System)</span></label>
                 <select  class="form-control" v-model="tank.rainwater"  @change="select_rain()">
                     <option disabled selected value="NA">---PLEASE SELECT---</option>
                     <option value="Yes">Yes</option>
                     <option value="No">No</option>
                 </select>  
            </div> 
             <div class="col-xl-6 col-sm-12 col-12" v-if="isRain"  >
                <label class="form-label"><span class="f-w-500 f-14">13.1) Rain Water Image</span></label><br>
                <img  v-if="tank.rain_pic && tank.rain_pic!=''" :src="tank.rain_pic" @click="caputureRainImage()" width="100"/>
               <img  v-else  src="../../assets/img_upload.png" width="100" @click="caputureRainImage()"/>  
            </div>
            <div class="col-xl-6 col-sm-12 col-12" v-if="isRain" >
                <label class="form-label"><span class="f-w-500 f-14">13.2)Remark</span></label>
                <input class="form-control" type="text" v-model="tank.rain_remark" >
            </div>
            
            
            
            
             <div class="col-xl-6 col-sm-12 col-12"   >
                <label class="form-label"><span class="f-w-500 f-14">14)इलेकट्रीक वाहन (EV Vehicle) </span></label>
                 <select  class="form-control" v-model="tank.ev"  @change="select_ev()">
                     <option disabled selected value="NA">---PLEASE SELECT---</option>
                     <option value="Yes">Yes</option>
                     <option value="No">No</option>
                 </select>  
            </div> 
             <div class="col-xl-6 col-sm-12 col-12" v-if="isEv"  >
                <label class="form-label"><span class="f-w-500 f-14">14.1) EV Vehicle Image</span></label><br>
                <img  v-if="tank.ev_pic && tank.ev_pic!=''" :src="tank.ev_pic" @click="caputureEvImage()" width="100"/>
               <img  v-else  src="../../assets/img_upload.png" width="100" @click="caputureEvImage()"/>  
            </div>
            <div class="col-xl-6 col-sm-12 col-12" v-if="isEv" >
                <label class="form-label"><span class="f-w-500 f-14">14.2)Remark</span></label>
                <input class="form-control" type="text" v-model="tank.ev_remark" >
            </div>
              
          </div>
            </div>
              <div class="card-footer">  
              <div class="mb-2">
                  <h6 v-show="!isOnline" class="text-danger">You Are Offline,Data will be saved locally.</h6>
              </div>
                <div class="col-12">
                        <button class="btn btn-primary" @click="save_survey">Submit Survey</button>
                        &nbsp;&nbsp; <button class="btn btn-danger" @click="clear_survey">Clear Inputs</button>
                    </div>
                    <div class="text-info" id="process_lbl"></div>
            </div>
            
            
                 </div>
                 </div>
      </div>
      </div>
   
    
  <CaptureImage v-if='isCapture' @backBtn="backBtn" @setImage="setImage"/>
  <CaptureImage v-if='isSolarCapture' @backBtn="backBtn" @setImage="setSolarImage"/>
  <CaptureImage v-if='isRainCapture' @backBtn="backBtn" @setImage="setRainImage"/>
  <CaptureImage v-if='isEvCapture' @backBtn="backBtn" @setImage="setEVImage"/>
  <!--<WebcamCapture v-if='isCapture' @backBtn="backBtn" @setImage="setImage"/>-->
  <!--<LocationPicker v-show='isLocation' @backBtnLoc="backBtnLoc" @setLocation="setLocation" :last_pins="last_pins" :userDetails="userDetails"/>-->
                <div :class="(isLocation)?'show':'hide'" >
                        <LocationPicker @backBtnLoc="backBtnLoc" @setLocation="setLocation" :last_pins="last_pins" :userDetails="userDetails"/>
                </div>
  </div>
</template>
<style scooped>
    .form-control{
                margin-bottom: 15px;
                padding: 1px 7px !important;
                border-color: black;
    }
    .card-body{
                
    }
    .select2-container .select2-selection--single{
        height: 30px !important;
         padding:  1px 7px !important;
    }
    .hide{
        display: none;
    }
    .show{
        display: block;
    }
    
</style>
<script>
import PageTitle from '@/components/PageTitle.vue';
import axios from 'axios';
import WebcamCapture from '@/components/WebcamCapture.vue';
import LocationPicker from '@/components/LocationPicker.vue';
import CaptureImage from '@/components/CaptureImage.vue';
import {   addSurveyRecord } from '@/dexie';
import { ref, onMounted } from 'vue';

export default {
  name: 'TankSurvey',
  components: {
        PageTitle ,WebcamCapture,LocationPicker,CaptureImage,addSurveyRecord
    },
    beforeRouteLeave(to, from, next) {
            
            this.isLocation=0;
            this.isCapture=0;
            next();
    },
    computed:{
            showSection(){
                return  this.showCreateSection;
                }
        },
      mounted() {
            if (this.$route.query.q) {
                this.getSurveyQues(this.$route.query.q);
            }else {
                
                        this.getBasics();
                  
            }
           
        }, 
        data() {
        return { 
          tank: {'iddate':this.today(),'byuser':'','tank_image':'','name':'','ward':'','property':'','phone':'','address':'','tank_location_lat':'','tank_location_lng':'','establishment':'','society':'','sanitation':'','empied':'','solar':'','solar_pic':'','solar_remark':'','rain_remark':'','rain_pic':'','ev_remark':'','ev_pic':'','rainwater':'','ev':''}, 
          survey_details: {name:''},
          isCapture:false,
          isLocation:false,
          isSubmitted:false,
          
          isSolarCapture:false,
          isSolar:false,
          
          isRainCapture:false,
          isRain:false,
          
          isEvCapture:false,
          isEv:false,
          
          tanknames:[],
          tankbotnical_name:[],
          last_pins:[],
          prev_details:{'society_name':'','road_name':''},
          userDetails:{'kmlurl':''},
          isDataLoaded:false ,
          location:{
                latitude: 18.57094038048649,
                longitude: 73.81621311358407
          },
          isOnline:  navigator.onLine, // Initial status
        }
    },
     beforeUnmount() {
    // Clean up event listeners
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  setup(){
       
    const newRecord = ref({ token: '', data: '' });  
    
         const loadtankRecords = async () => {
           const data_here= await getAlltanks();
               if(data_here.length>0){
                    //self.tanknames  = data_here[0].data;
                    for (var i = 0; i < data_here[0].data.length; i++) {
                        console.log(data_here[0].data[i]);
                    }
               }
     }
     
  }, 
    methods: {
        today(){
            var today = new Date()
            var month=today.getMonth()+1;
            var day=today.getDate();
            
            var hr=today.getHours();
            var min=today.getMinutes();
            var sec=today.getSeconds();
            
            var now_date = (today.getFullYear() + '-' + ((month<10)?'0'+month:month) + '-' + ((day<10)?'0'+day:day));//today.getDate());
            var now_time = (((hr<10)?'0'+hr:hr) + ":" +((min<10)?'0'+min:min) + ":" + ((sec<10)?'0'+sec:sec) ).toString();
            
            return now_date+' '+now_time;
        },
         updateOnlineStatus() {
          this.isOnline = navigator.onLine;
        },
        clear_survey(){
            var self=this;
           
             self.tank={'iddate':self.today(),'byuser':'','tank_image':'','name':'','ward':'','property':'','phone':'','address':'','tank_location_lat':'','tank_location_lng':'','establishment':'','society':'','sanitation':'','empied':'','solar':'','solar_pic':'','solar_remark':'','rain_remark':'','rain_pic':'','ev_remark':'','ev_pic':'','rainwater':'','ev':''};
           
                                          
                                          
             
             this.isSubmitted=false;
             
            if(this.prev_details.society_name)
                this.tank.society_name=this.prev_details.society_name;
            
            if(this.prev_details.road_name)
             this.tank.road_name=this.prev_details.road_name;
             
        },
     async save_survey() {
          
          if(!this.isSubmitted || this.isSubmitted){  // for double upload
              this.isSubmitted=true;
              if(this.tank.name.length==0)
                  this.$swal("Please Enter Name.");
              else{
                //$(".loader-wrapper").show(); 
                
                $("#process_lbl").html("Saving In Background");
                
                    var self = this;
                             const blob = self.dataURLtoBlob(this.tank.image);
                             const solar_blob = self.dataURLtoBlob(this.tank.solar_pic);
                             const rain_blob = self.dataURLtoBlob(this.tank.rain_pic);
                             const ev_blob = self.dataURLtoBlob(this.tank.ev_pic);
                                    
                    //07.09.2024
                    if(this.isOnline){//11.09.2024
                            self.prev_details.society_name=self.tank.society_name;
                            self.prev_details.road_name=self.tank.road_name;
                            
                            localStorage['latLong']=self.tank.tank_location_lat;
                            
                                 
                                const formData = new FormData();
                                formData.append('image', blob, 'captured_photo.png'); 
                                
                                if(solar_blob)
                                    formData.append('solar_image', solar_blob, 'solar_image.png'); 
                                else
                                    formData.append('solar_image',""); 
                                    
                                if(ev_blob) 
                                    formData.append('ev_image', ev_blob, 'ev_image.png'); 
                                else
                                    formData.append('ev_image',""); 
                                
                                if(rain_blob) 
                                    formData.append('rain_image', rain_blob, 'rain_image.png'); 
                                else
                                    formData.append('rain_image',""); 
                                    
                                
                                formData.append('ques', JSON.stringify(self.tank)); 
                                
                                
                            await axios.post('saveTankSurvey',formData).then(function (response) {
                               
                               //self.$swal(response.data.msg);
                                $("#process_lbl").html(response.data.msg);
                                
                               self.surveys  = response.data.surveys ;   
                                $(".loader-wrapper").hide();
                               
                              //  window.scrollTo(0, 0);
                            }).catch(async (error) => { 
                                   if (error) {
                                       console.log(error)
                                       if(!error.response){
                                           self.tank.iddate=this.today();
                                             const id = await addSurveyRecord({ token:localStorage['token'], data: JSON.stringify(self.tank),blob:blob });
                                             if(id>0){
                                                     $("#process_lbl").html(self.tank.iddate+") Data Saved Offline");//self.$swal("Data Saved Offline");
                                                   this.isSubmitted=false;
                                             }
                                             else{
                                                      $("#process_lbl").html("Failed To Save Offline");//self.$swal("Failed To Save Offline");
                                                    this.isSubmitted=false;
                                             }      
                                                     $(".loader-wrapper").hide();
                                       }
                                   }  
                              });
                    }else{
                      //  alert(localStorage['token']);
                      const id = await addSurveyRecord({ token:localStorage['token'], data: JSON.stringify(self.tank),blob:blob });
                         if(id>0){
                               self.$swal("Data Saved Offline");
                               this.isSubmitted=false;
                         }
                         else{
                                self.$swal("Failed To Save Offline");
                                this.isSubmitted=false;
                         }      
                                 $(".loader-wrapper").hide();
                                 
                                 
                    }
              }
          }
        },
        setRadio(what,item){
            item.ans=what;
        },
        
        setImage(value){ 
            this.tank.image=value;
             this.isCapture=false; 
             this.scrollToBottom();
        },
        
        setSolarImage(value){ 
            this.tank.solar_pic=value;
             this.isSolarCapture=false; 
             this.scrollToBottom();
          
        },
        caputureSolarImage(){
            this.isSolarCapture=true;
        },
        
        
        
        setRainImage(value){ 
            this.tank.rain_pic=value;
             this.isRainCapture=false; 
             this.scrollToBottom();
          
        },
        caputureRainImage(){
            this.isRainCapture=true;
        },
        
        
        
        setEVImage(value){ 
            this.tank.ev_pic=value;
             this.isEvCapture=false; 
             this.scrollToBottom();
          
        },
        caputureEvImage(){
            this.isEvCapture=true;
        },
        
        scrollToBottom(){
                  this.$nextTick(() => {
                    const container = this.$refs.surveyContainer;
                    if (container) {
                      container.scrollTop = container.scrollHeight;
                    }
                  });
        },
        backBtn(){  
             this.isCapture=false;
             this.isSolarCapture=false;
             this.isRainCapture=false;
             this.isEvCapture=false;
        },
        caputureImage(){
            this.isCapture=true;
        },
       
     async  getBasics() {
            var self = this;
           
           if(this.isOnline){
                    axios.post('getReportBasics',{location:this.location}).then(function (response) {
                        self.tanknames  = response.data.tanknames ;     
                        self.last_pins  = response.data.last_pins ;    
                        self.userDetails=response.data.userdetails;
                        
                        self.tank.society_name=response.data.last_survey.society_name;     
                        self.tank.road_name=response.data.last_survey.road_name;     
                        
                        self.prev_details.society_name=self.tank.society_name;
                        self.prev_details.road_name=self.tank.road_name;
                       
                        // cleartanks();
                       // addtankRecord({ data: JSON.stringify(self.tanknames) });
                       // window.scrollTo(0, 0);
                    }).then(()=>{
                                  $("#tank_names").select2().on('change', function (event) {
                                      
                                    const selectedProduct = self.tanknames.find(item => item.marathi_name === event.target.value);
                                      if (selectedProduct) {
                                          self.tank.local_name= selectedProduct.marathi_name; 
                                          self.tank.comman_name= selectedProduct.hindi_name;
                                          //self.tank.other_name= selectedProduct.english_name; 
                                          self.tank.botnical_name= selectedProduct.botnical_name; 
                                          
                                          $("#b_tank_names").val(self.tank.botnical_name).select2();
                                          $("#c_tank_names").val(self.tank.comman_name).select2(); 
                                      }
                                    
                            }); 
                            
                            
                               $("#b_tank_names").select2().on('change', function (event) {
                                      
                                    const selectedProduct = self.tanknames.find(item => item.botnical_name === event.target.value);
                                      if (selectedProduct) {
                                          self.tank.local_name= selectedProduct.marathi_name; 
                                          self.tank.comman_name= selectedProduct.hindi_name;
                                          //self.tank.other_name= selectedProduct.english_name; 
                                          self.tank.botnical_name= selectedProduct.botnical_name; 
                                          
                                          $("#tank_names").val(self.tank.local_name).select2();
                                          $("#c_tank_names").val(self.tank.comman_name).select2(); 
                                           
                                      }
                                    
                            }); 
                            
                             $("#c_tank_names").select2().on('change', function (event) {
                                      
                                    const selectedProduct = self.tanknames.find(item => item.hindi_name === event.target.value);
                                      if (selectedProduct) {
                                          self.tank.local_name= selectedProduct.marathi_name; 
                                          self.tank.comman_name= selectedProduct.hindi_name;
                                          //self.tank.other_name= selectedProduct.english_name; 
                                          self.tank.botnical_name= selectedProduct.botnical_name; 
                                          
                                          
                                          $("#tank_names").val(self.tank.local_name).select2();
                                          $("#b_tank_names").val(self.tank.botnical_name).select2(); 
                                            
                                          //alert(selectedProduct.marathi_name+'---'+selectedProduct.hindi_name+'---'+selectedProduct.botnical_name);
                                      }
                                    
                            }); 
                            
                            
                            
                                 // $("#tank_bot_names").select2();
                    }); 
           }else{
               //self.tanknames  = JSON.parse(getAlltanks()[0]);
               const data_here= await getAlltanks();
               if(data_here.length>0){
                    self.tanknames  = JSON.parse(data_here[0].data);
                     $("#tank_names").select2().on('change', function (event) {
                                    const selectedProduct = self.tanknames.find(item => item.marathi_name === event.target.value);
                                      if (selectedProduct) {
                                          self.tank.local_name= selectedProduct.marathi_name; 
                                          self.tank.comman_name= selectedProduct.hindi_name;
                                          self.tank.other_name= selectedProduct.english_name; 
                                          self.tank.botnical_name= selectedProduct.botnical_name; 
                                      }
                     });
               }
                
               //console.log(data_here[0].data);
           }
        },
      getSurveyQues(servey_id) {
            var self = this;
            const blob = self.dataURLtoBlob(this.photo);
            axios.post('getFrontSurveyQues',{servey_id:servey_id}).then(function (response) {
                self.ques  = response.data.ques ;   
                self.userDetails  = response.data.userDetails ;   
                self.survey_details  = response.data.survey_details ;   
               
            });
        },
        getLocation(){
            navigator.geolocation.getCurrentPosition(this.success, this.error);
        },
        
        error(err) {
            $("#locError").html(err.message);
            this.isLocation=true;
            //this.tank.tank_location_lat=err.message;
            console.log(err);
        }, 
        success(position) {
           // const latitude  = position.coords.latitude;
           // const longitude = position.coords.longitude;
          //  this.tank.tank_location_lat=latitude+","+longitude;
            this.isLocation=true;
            $("#locError").html('');
        },
        
        

        dataURLtoBlob(dataURL) {
             try {
                  const byteString = atob(dataURL.split(',')[1]);
                  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
                  const ab = new ArrayBuffer(byteString.length);
                  const ia = new Uint8Array(ab);
                  for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                  }
                  return new Blob([ab], { type: mimeString });
             } catch (error) {
                console.error("Error converting dataURL to Blob:", error);
                return null;
              }
        }, 
        setLocation(value){ 
           const latitude  = value.lat;
           const longitude = value.lng;
          this.tank.tank_location_lat=latitude+","+longitude;
           
           this.isLocation=false; 
        },
        backBtnLoc(){  
             this.isLocation=false;
        },
        
        
        select_solar(){
            if(this.tank.solar=="Yes")    
                this.isSolar=true;
            else
                this.isSolar=false;
        },
        
        
        select_rain(){
            if(this.tank.rainwater=="Yes")    
                this.isRain=true;
            else
                this.isRain=false;
        },
        
        
        select_ev(){
            if(this.tank.ev=="Yes")    
                this.isEv=true;
            else
                this.isEv=false;
        }
        
    }
}
</script>
